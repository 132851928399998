/************************************************************************************************************
 *   routing config
 ************************************************************************************************************/



//Load all components in async mode to reduce loading time
const main = () => import('@/components/MainPage.vue');
const sdrmac = () => import('@/components/sdr/mac/SdrMacPage.vue');
const stationlist = () => import('@/components/StationList/StationList.vue');

export default [
    {
        name: "main",
        path: "/",
        component: main,
        meta: {}
    },
    {
        name: "sdrmac",
        path: '/sdrmac',
        component: sdrmac,
        meta: {}
    },
    {
        name: "stationlist",
        path: '/stationlist',
        component: stationlist,
        meta: {}
    }
]